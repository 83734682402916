<template>
  <b-card-code title="微信列表">
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="搜索"
            type="text"
            class="d-inline-block"
            @input="handleSearch"
          />
        </div>
      </b-form-group>
    </div>
    <groupset
      :chatroomset="chatroomset"
      @refreshChangeDataList="refreshChangeDataList"
    ></groupset>
    <!-- table -->
    <vue-good-table
      mode="remote"
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true,
        selectAllByGroup: true,
      }"
      :pagination-options="{
        enabled: true,
      }"
      @on-sort-change="onSortChange"
    >
      <template slot="table-row" slot-scope="props">
        <span
          v-if="props.column.field === 'chatroomid'"
          v-b-tooltip.hover
          :title="props.row.chatroomid"
        >
          <b-avatar :src="props.row.avatar" class="mx-1" />
          {{ props.row.nickname }}
        </span>
        <span
          v-else-if="props.column.field === 'ownername'"
          v-b-tooltip.hover
          :title="props.row.ownerid"
        >
          {{ props.row.ownername }}
        </span>
        <span
          v-else-if="props.column.field === 'sendwechat'"
          v-b-tooltip.hover
          :title="props.row.sendwechat"
        >
          {{ props.row.atwechat }}
        </span>
        <span
          v-else-if="props.column.field === 'monitorchatroomid'"
          v-b-tooltip.hover
          :title="props.row.monitorchatroomid"
        >
          {{ showchatroomname(props.row.monitorchatroomid) }}
        </span>
        <span
          v-else-if="props.column.field === 'monitorwechat'"
          v-b-tooltip.hover
          :title="props.row.monitorwechat"
        >
          {{
            showmembersname(
              props.row.monitorwechat,
              props.row.monitorchatroomid
            )
          }}
        </span>
        <span v-else-if="props.column.field === 'open'">
          <b-badge :variant="statusVariant(props.row.open)">
            {{ props.row.open == "0" ? "关闭" : "开启" }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'type'">
          {{ sendtype(props.row.type) }}
        </span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="aa(props.row)">
                <feather-icon icon="Edit2Icon" class="mr-50" />
                <span>编辑</span>
              </b-dropdown-item>
              <b-dropdown-item @click="deletebyid(props.row.id)">
                <feather-icon icon="TrashIcon" class="mr-50" />
                <span>删除</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <!-- page length -->
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> 显示 </span>
            <b-form-select
              v-model="pageLength"
              :options="pages"
              class="mx-1"
              @input="handlePageChange"
            />
            <span class="text-nowrap"> {{ total }} 条 </span>
          </div>

          <!-- pagination -->
          <div>
            <b-pagination
              :value="1"
              :total-rows="total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  VBTooltip,
  BAvatar,
  BBadge,
  BButton,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BForm,
  BModal,
  VBModal,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import Prism from "vue-prism-component";
import store from "@/store/index";
import useJwt from "@/auth/jwt/useJwt";
import Ripple from "vue-ripple-directive";
import groupset from "@/views/rebate/GroupSet";
export default {
  components: {
    VBTooltip,
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BButton,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    Prism,
    BDropdownItem,
    BDropdown,
    BForm,
    BModal,
    VBModal,
    groupset,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      log: [],
      pageLength: 10,
      total: 0,
      cid: 0,
      rs: false,
      chatroomname: "",
      membersname: "",
      chatroomset: "",
      desc: "",
      search: "",
      roleOptions: [],
      dir: false,
      pages: ["10", "20", "40", "100"],
      columns: [
        {
          label: "群名称",
          field: "chatroomid",
        },

        {
          label: "群主",
          field: "ownername",
        },
        {
          label: "所在微信",
          field: "sendwechat",
        },
        {
          label: "所属部门",
          field: "dname",
        },
        {
          label: "自动发送",
          field: "open",
        },
        {
          label: "产品类型",
          field: "type",
        },
        {
          label: "监听群",
          field: "monitorchatroomid",
        },
        {
          label: "监听人",
          field: "monitorwechat",
        },
        {
          label: "操作",
          field: "action",
        },
      ],
      rows: [],
      searchTerm: "",
    };
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        1: "light-success",
        0: "light-danger",

        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },

    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },

  created() {
    const user = JSON.parse(localStorage.getItem("userData"));
    this.cid = user.cid;
    this.did = user.did;
    useJwt
      .groupconfigAll({
        cid: this.cid,
        did: this.did,
        page: 1,
        rows: this.pageLength,
        desc: this.desc,
      })
      .then((res) => {
        // pagelength 一页显示多少条
        this.total = res.data.data.records;
        console.log(JSON.stringify(res.data.data.rows))
        this.rows = res.data.data.rows;
      });
  },
  methods: {
    showchatroomDetail(member) {
      //   useJwt.findContactinfoByWechatidFriendid(member.wechatid,member)
    },
    // 父组件定义刷新的页面方法
    refreshChangeDataList() {
      const user = JSON.parse(localStorage.getItem("userData"));
      this.cid = user.cid;
      this.did = user.did;
      useJwt
        .groupconfigAll({
          cid: this.cid,
          did: this.did,
          page: 1,
          rows: this.pageLength,
          desc: this.desc,
        })
        .then((res) => {
          // pagelength 一页显示多少条
          this.total = res.data.data.records;
          this.rows = res.data.data.rows;
        });
    },
    showchatroomname(chatroomid) {
      console.log("chatroomid" + chatroomid);
      useJwt
        .queryChatRoominfoBychatroomid({
          cid: this.cid,
          chatroomid: chatroomid,
        })
        .then((res) => {
          this.chatroomname = res.data.data.nickname;
        });
      return this.chatroomname;
    },
    showmembersname(wechatid, chatroomid) {
      console.log("chatroomid" + chatroomid);
      useJwt
        .queryChatRoommemberBymwechatid({
          mwechat: wechatid,
          chatroomid: chatroomid,
        })
        .then((res) => {
          this.membersname = res.data.data.mwechatnick;
        });
      return this.membersname;
    },
    aa(chatroom) {
      this.chatroomset = JSON.stringify(chatroom);
      this.$bvModal.show("modal-xl");
    },
    deletebyid(id) {
      useJwt
        .queryChatRoommemberBymwechatid({
          mwechat: wechatid,
          chatroomid: chatroomid,
        })
        .then((res) => {
          this.membersname = res.data.data.mwechatnick;
        });
    },
    sendtype(type) {
      let array = type.split(",");
      let typename = "";
      array.forEach(function (item, index) {
        switch (item) {
          // server 登陆结果 ok
          case "0":
            typename += "综合,";
            break;
          case "1":
            typename += "女装,";
            break;
          case "2":
            typename += "母婴,";
            break;
          case "3":
            typename += "美妆,";
            break;
          case "4":
            typename += "居家日用,";
            break;
          case "5":
            typename += "鞋品,";
            break;
          case "6":
            typename += "美食,";
            break;
          case "7":
            typename += "文娱车品,";
            break;
          case "8":
            typename += "数码家电,";
            break;
          case "9":
            typename += "男装,";
            break;
          case "10":
            typename += "内衣,";
            break;
          case "11":
            typename += "箱包,";
            break;
          case "12":
            typename += "配饰,";
            break;
          case "13":
            typename += "户外运动,";
            break;
          case "14":
            typename += "家装家纺,";
            break;
        }
      });
      return typename.substring(0, typename.length - 1);
    },
    confirmText(id) {
      this.$swal({
        title: "确定要删除吗?",
        text: "删除后无法恢复!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$swal({
            icon: "error",
            title: "删除失败!",
            text: "请联系管理员处理",
            customClass: {
              confirmButton: "btn btn-error",
            },
          });
        }
      });
    },

    handleSearch(searching) {
      useJwt
        .groupconfigAll({
          cid: this.cid,
          page: 1,
          rows: this.pageLength,
          wechatnick: searching,
        })
        .then((res) => {
          this.total = res.data.data.records;
          this.rows = res.data.data.rows;
        });
      this.search = searching;
    },
    handleChangePage(page) {
      useJwt
        .groupconfigAll({
          cid: this.cid,
          page: page,
          rows: this.pageLength,
          desc: this.desc,
          wechatnick: this.search,
        })
        .then((res) => {
          this.total = res.data.data.records;
          this.rows = res.data.data.rows;
        });
    },
    handlePageChange(active) {
      useJwt
        .groupconfigAll({
          cid: this.cid,
          page: 1,
          rows: active,
          desc: this.desc,
          nickname: this.search,
        })
        .then((res) => {
          // pagelength 一页显示多少条
          this.pageLength = active;
          this.rows = res.data.data.rows;
          this.total = res.data.data.records;
        });
    },
    onSortChange(params) {
      this.desc = `${params[0].field}`;
      if (this.desc == "createTime") {
        this.desc = "create_time" + ` ${params[0].type}`;
      } else {
        this.desc = `${params[0].field} ${params[0].type}`;
      }

      useJwt
        .groupconfigAll({
          cid: this.cid,
          page: 1,
          rows: this.pageLength,
          desc: this.desc,
          nickname: this.search,
        })
        .then((res) => {
          this.total = res.data.data.records;
          this.rows = res.data.data.rows;
        });
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
